<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="3" class="px-0">
          <v-card class="pa-2">
            <v-row class="px-1">
              <v-col v-for="(field, index) in fields" :key="index" class="d-flex child-flex px-1 my-1 py-1" cols="12" lg="6" md="6">
                <v-card class="py-2 px-2" outlined :draggable="!Boolean(typeof editFieldIndex === 'number')" @dragstart="dragStart(field)">
                  <v-icon class="mr-2">{{ field.icon || 'mdi-gamepad-right' }}</v-icon>
                  <span class="font-weight-bold px-1"> {{field.name}} </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card>
            <form-fields 
              :fields="modelObj.fields"
              :modelObj="{}" :fromBuilder="true" ref="form"
              @fieldEdit="editFieldHandler"
              @fieldDelete="deleteFieldHandler">
            </form-fields>
          </v-card>
        </v-col>
      </v-row>
      <!-- Edit Field Form -->
      <v-navigation-drawer v-model="showFieldEditDrawer" stateless app temporary height="100vh" right width="30%">
        <v-card height="94vh" class="pa-0" tile outlined>
          <v-card-title class="primary">
            <h5 class="white--text">{{ draggingField ? 'Save' : 'Update'}} Field Property</h5>
          </v-card-title>
          <v-divider class="mx-2 mb-2"></v-divider>
          <v-form ref="fieldsForm">
            <v-row class="px-2">
              <v-col cols="12">
                <v-text-field hide-details v-model="fieldModelObj.label" label="label" outlined dense :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <template cols="12" v-if="fieldModelObj.type === 'SELECT' || fieldModelObj.type === 'RADIO'">
                <v-col cols="12" v-if="fieldModelObj.type === 'SELECT'">
                  <v-checkbox v-model="fieldModelObj.isMultiselect" hide-details dense outlined label="Is Multiselect"></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="fieldModelObj.type === 'SELECT'">
                  <v-card-subtitle class="pa-0 ma-0">Select Type</v-card-subtitle>
                  <v-radio-group mandatory v-model="fieldModelObj.selectType" row hide-details dense>
                    <v-radio v-for="(n, index) in ['CUSTOM', 'MODULE']" :key="index" :label="`${n}`" :value="n"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="fieldModelObj.selectType === 'CUSTOM' || fieldModelObj.type === 'RADIO'">
                  <v-combobox
                    @keydown="preventEvent"
                    @keyup="preventEvent"
                    @keypress="preventEvent"
                    v-model="fieldModelObj.selectItems"
                    :item-disabled="checkIsOptionInternal"
                    :search-input.sync="optionSearch"
                    :items="fieldModelObj.selectItemsList"
                    label="Add Options"
                    outlined
                    dense
                    hide-details
                    multiple
                    persistent-hint
                    small-chips
                  >
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" :close="!data.item.isinternal" @click="data.select" @click:close="removeFromOptionsList(data.item)">
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <template v-else-if="fieldModelObj.selectType === 'MODULE'">
                  <v-col cols="12">
                    <v-select :items="systemModules" item-text="name" item-value="value" v-model="fieldModelObj.selectModule" hide-details dense outlined></v-select>
                  </v-col>
                  <v-col cols="12" v-if="fieldModelObj.selectModule && fieldModelObj.selectModule === 'users'">
                    <v-select label="Filter Roles" :items="listOfUserRoles" item-text="name" item-value="_id" v-model="fieldModelObj.filterByUserRoles" hide-details dense outlined multiple></v-select>
                  </v-col>
                </template>
              </template>
              <v-col cols="12">
                <v-select  :items="[2, 3, 4, 6, 12]" hide-details v-model="fieldModelObj.cols" label="Column" outlined dense :rules="setValidation('requiredValidation')"></v-select>
              </v-col>
              <v-col cols="12" class="my-1 py-0">
                <v-checkbox hide-details v-model="fieldModelObj.isRequired" label="Is Required" outlined dense></v-checkbox>
              </v-col>
              <v-col cols="12" class="my-1 py-0">
                <v-checkbox hide-details v-model="fieldModelObj.isactive" label="Is Active"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <template v-slot:append>
          <v-divider></v-divider>
          <div class="pa-2 d-flex justify-center">
            <v-btn x-small color="primary" @click="saveFieldPropertyHandler()" small>{{ draggingField ? 'Save' : 'Update'}}</v-btn>
            <v-btn class="ml-1" x-small color="error" @click="closeEditFieldDrawer()" small>{{ 'Cancel' }}</v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['modelObj'],
  data () {
    return {
      showFieldEditDrawer: false,
      listOfUserRoles: [],
      hoveringOnPanel: null, 
      optionSearch: '',
      hover: null,
      fieldBasedProperties: {},
      editFieldIndex: null,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      fieldModelObjKeys: [],
      fieldModelObj: {
        selectItems: [],
        selectItemsList: []
      },
      draggingField: null,
      fields: [
        {type: 'TEXT', name: 'Text Field', image: '', icon: '' },
        {type: 'TEXTAREA', name: 'Text Area', image: '', icon: '' },
        {type: 'NUMBER', name: 'Number', image: '', icon: '' },
        {type: 'CHECKBOX', name: 'Checkbox', image: '', icon: 'mdi-checkbox-marked-outline' },
        {type: 'SELECT', name: 'Select', image: '', icon: 'mdi-ray-end' },
        {type: 'RADIO', name: 'Radio', image: '', icon: 'mdi-radiobox-marked' },
        {type: 'EMAIL', name: 'Email', image: '', icon: 'mdi-email-outline' },
        {type: 'DATE_PICKER', name: 'Date Picker', image: '', icon: 'mdi-calendar' },
        {type: 'TIME_PICKER', name: 'Time Picker', image: '', icon: 'mdi-timer' },
        {type: 'TITLE', name: 'Title', image: '', icon: '' },
        {type: 'DIVIDER', name: 'Divider', image: '', icon: '' },
        {type: 'PANEL', name: 'Panel', image: '', icon: '' }
      ]
    }
  },
  computed: {
    systemModules () {
      return [
        { name: 'Users', value: 'users' },
        { name: 'User Roles', value: 'user_roles' },
        { name: 'Classes', value: 'classes' },
        { name: 'Subjects', value: 'subjects' },
        { name: 'Vehicles', value: 'vehicles' },
        { name: 'Routes', value: 'routes' },
        { name: 'Shifts', value: 'shifts' },
      ]
    }
  },
  components: {
    'form-fields': () => import('@/views/FormBuilder/Builder/Components/FormFields.vue')
  },
  watch: {
    optionSearch (val, prev) {
      if (!val && prev) {
        this.fieldModelObj.selectItems = this.fieldModelObj.selectItems.map((item) => {
          return (item && typeof item === 'string') ? { text: item, value: item.toLowerCase() } : item
        })
        this.fieldModelObj.selectItemsList.push({ text: prev, value: prev.toLowerCase() })
      }
    }
  },
  mounted () {
    this.$store.dispatch('getUserRoles').then((data) => {
      this.listOfUserRoles = data
    })
  },
  methods: {
    checkIsOptionInternal (item) {
      return item.isinternal
    },
    removeFromOptionsList (val) {
      let index = this.fieldModelObj.selectItems.findIndex(v => v.value === val.value)
      if (index > -1) this.fieldModelObj.selectItems.splice(index, 1)
      index = this.fieldModelObj.selectItemsList.findIndex(v => v.value === val.value)
      if (index > -1) this.fieldModelObj.selectItemsList.splice(index, 1)
    },
    preventEvent (e) {
      // if (!this.optionSearch && (e.key === "Backspace" || e.key === "Delete")) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    dragStart (field) {
      this.draggingField = field
    },
    editFieldHandler(fieldId, panelId) {
      let model = null
      if (fieldId) {
        const fieldIndex = this.modelObj.fields.findIndex(x => x._id === fieldId)
        if (fieldIndex >= 0) model = this.$_copy(this.modelObj.fields[fieldIndex])
      } else {
        let fieldObj = {
          type: this.draggingField.type,
          cols: 6,
          isactive: true,
          panelId
        }
        if (fieldObj.type === 'RADIO') {
          fieldObj.selectItemsList = []
          fieldObj.selectItems = []
        } else if (fieldObj.type === 'SELECT') {
          fieldObj.itemGroups = [{ selectType : 'CUSTOM', selectItems: [], selectItemsList: [] }]
        }
        model = this.$_copy(fieldObj)
        console.log(model)
      }
      if (model) {
        if (model.type === 'RADIO') {
          model.selectItems = model.selectItems || []
          model.selectItemsList = model.selectItems.map(v => {
            if (v && typeof v === 'string') v = { text: v, value: v.toLowerCase() }
            return v
          })
        } else if (model.type === 'SELECT') {
          // will do the changes heres
          model.selectItems = model.selectItems || []
          model.selectItemsList = model.selectItems.map(v => {
            if (v && typeof v === 'string') v = { text: v, value: v.toLowerCase() }
            return v
          })
        }
        this.fieldModelObj = this.$_copy(model)
        this.showFieldEditDrawer = true
      }
    },
    deleteFieldHandler (fieldId) {
      const fieldIndex = this.modelObj.fields.findIndex(x => x._id === fieldId)
      if (fieldIndex >= 0) this.modelObj.fields.splice(fieldIndex, 1)
    },
    closeEditFieldDrawer () {
      this.editFieldIndex = null
      this.showFieldEditDrawer = false
    },
    async saveFieldPropertyHandler () {
      if (this.$refs.fieldsForm.validate()) {
        const field = this.$_copy(this.fieldModelObj)
        if (!field.model && field.label) {
          field.model = field.label.replace(/[^a-z0-9]/gi, '_').toLowerCase()
          var number = 1
          while (this.modelObj.fields.some(x => x.model === field.model)) {
            field.model = `${field.model}_${number}`
            number++
          }
        }
        const fields = this.$_copy(this.modelObj.fields)
        if (!field._id) {
          field._id = await this.$_getNewObjectId()
          fields.push({ ...field })
        } else {
          const fieldIndex = this.modelObj.fields.findIndex(x => x._id === field._id)
          if (fieldIndex >= 0) fields[fieldIndex] = { ...field }
        }
        this.$set(this.modelObj, 'fields', fields)
        this.fieldModelObj = {}
        this.closeEditFieldDrawer()
      } else this.$root.$emit('snackbar', { message: 'Validation Error', color: 'error' })
    }
  }

}
</script>
